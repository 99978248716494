<template lang="">
  <v-ons-navigator
    swipeable
    v-model:page-stack="pageStack"
    @push-page="pageStack = [...pageStack, $event]"
  ></v-ons-navigator>
</template>
<script>
import HomeView from "./views/HomeView.vue";
import { markRaw } from "vue";

export default {
  data() {
    return {
      pageStack: [markRaw(HomeView)],
    };
  },
};
</script>
<style lang=""></style>

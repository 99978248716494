import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import "./assets/main.css";

import VueOnsen from "vue-onsenui/dist/vue-onsenui.js";

createApp(App).use(router).use(VueOnsen).mount("#app");

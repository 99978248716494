<template lang="">
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-back-button>Kembali</v-ons-back-button>
      </div>
      <div class="center">News</div>
    </v-ons-toolbar>
    <v-ons-card class="v-ons-card" v-for="x in newsData" :key="x.id">
      <div @click="pushNewsDetail(x.id)">
        <img :src="x.yoast_head_json.og_image[0].url" alt="" srcset="" />
        <p>{{ x.title.rendered }}</p>
      </div>
    </v-ons-card>
  </v-ons-page>
</template>
<script>
import axios from "axios";
import NewsDetail from "./NewsDetail.vue";
import { markRaw } from "vue";

export default {
  data() {
    return {
      newsData: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get("https://mitsubishi-kaltimkaltara.com/api/news")
        .then((response) => {
          this.newsData = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    pushNewsDetail(news_id) {
      this.$emit("push-page", {
        extends: markRaw(NewsDetail),
        onsNavigatorProps: {
          news_id: news_id,
        },
      });
    },
  },
};
</script>
<style scoped>
.v-ons-card {
  padding: 0 0 10px 0 !important;
}
.v-ons-card img {
  width: 100%;
}

.v-ons-card p {
  padding: 0 10px;
}
</style>

<template lang="">
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-back-button>Kembali</v-ons-back-button>
      </div>
      <div class="center">News</div>
    </v-ons-toolbar>
    <v-ons-card class="v-ons-card">
      <small>{{ dateMoment(post.date) }}</small>
      <h4>
        <b>{{ post_title }}</b>
      </h4>
      <img :src="post_img" alt="" style="width: 100%" />
      <hr />
      <p v-html="post_content"></p>
    </v-ons-card>
  </v-ons-page>
</template>
<script>
import axios from "axios";
import moment from "moment";
import "moment/locale/id";
moment.locale("id");
export default {
  data() {
    return {
      post: "",
      post_title: "",
      post_content: "",
      post_img: "",
    };
  },
  props: {
    news_id: Number,
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get("https://mitsubishi-kaltimkaltara.com/api/post/" + this.news_id)
        .then((response) => {
          this.post = response.data;
          this.post_title = response.data.title.rendered;
          this.post_content = response.data.content.rendered;
          this.post_img = response.data.yoast_head_json.og_image[0].url;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    dateMoment: function (date) {
      return moment(date).format("DD MMMM YYYY");
    },
  },
};
</script>
<style scoped></style>

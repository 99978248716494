<template>
  <v-ons-page>
    <div class="logo">
      <img src="/img/logo-dwindo.png" alt="" srcset="" style="width: 70px" />
      <h4><b>PT. MAHAKAM BERLIAN SAMJAYA</b></h4>
    </div>
    <v-ons-card @click="pushHargaMobil" class="v-ons-card">
      <img src="/img/harga.png" alt="" srcset="" />
      <h4 class="center"><b>Harga Mobil</b></h4>
    </v-ons-card>
    <v-ons-card @click="pushNews" class="v-ons-card">
      <img src="/img/news.png" alt="" srcset="" />
      <h4 class="center"><b>News</b></h4>
    </v-ons-card>
    <v-ons-card @click="pushPromo" class="v-ons-card">
      <img src="/img/promo.png" alt="" srcset="" />
      <h4 class="center"><b>Promo</b></h4>
    </v-ons-card>
  </v-ons-page>
</template>

<script>
// @ is an alias to /src
import HargaMobil from "./HargaMobil.vue";
import NewsView from "../views/news/News.vue";
import PromoView from "../views/promo/Promo.vue";
import { markRaw } from "vue";
export default {
  name: "HomeView",
  methods: {
    pushHargaMobil() {
      this.$emit("push-page", markRaw(HargaMobil));
    },
    pushNews() {
      this.$emit("push-page", markRaw(NewsView));
    },
    pushPromo() {
      this.$emit("push-page", markRaw(PromoView));
    },
  },
};
</script>
<style scoped>
.logo {
  background-image: url("../../public/img/header.jpg");
  background-position: top center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.logo img {
  margin: 10px 0 0 0;
}

.v-ons-card {
  display: flex;
  justify-content: start;
  flex-direction: row;
  border-radius: 10px;
}

.v-ons-card h4 {
  margin-left: 20px;
}

.v-ons-card img {
  width: 40px !important;
}
</style>

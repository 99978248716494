<template lang="">
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-back-button>Kembali</v-ons-back-button>
      </div>
      <div class="center">Car List</div>
    </v-ons-toolbar>
    <v-ons-card v-for="x in dataMobil" class="card">
      <div @click="pushCarDetail(x.group)">
        <img :src="x.img" alt="" />
        <b> {{ x.car_type }}</b>
      </div>
    </v-ons-card>
  </v-ons-page>
</template>
<script>
import HargaMobilDetail from "./HargaMobilDetail.vue";
import { markRaw } from "vue";
import axios from "axios";
export default {
  data() {
    return {
      dataMobil: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get("https://mitsubishi-kaltimkaltara.com/api/car-list")
        .then((response) => {
          console.log(response);
          this.dataMobil = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    pushCarDetail(car_group) {
      this.$emit("push-page", {
        extends: markRaw(HargaMobilDetail),
        onsNavigatorProps: {
          car_group: car_group,
        },
      });
    },
  },
};
</script>
<style scoped>
.card {
  padding: 0 0 10px 0 !important;
}
.card img {
  width: 100%;
}

.card b {
  padding: 10px 10px;
}
</style>

<template lang="">
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-back-button>Kembali</v-ons-back-button>
      </div>
      <div class="center">Car List</div>
    </v-ons-toolbar>
    <v-ons-card v-for="x in dataMobil">
      <div class="card-detail">
        <img
          :src="'https://mitsubishi-kaltimkaltara.com/' + x.car_img"
          alt=""
        />
        <span style="margin-top: 10px">{{ x.car_type }}</span>
        <span>{{ x.car_name }}</span>
        <span>Harga: {{ x.car_price }}</span>
      </div>
    </v-ons-card>
  </v-ons-page>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      dataMobil: "",
    };
  },
  props: {
    car_group: String,
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get(
          "https://mitsubishi-kaltimkaltara.com/api/car-list/" + this.car_group
        )
        .then((response) => {
          this.dataMobil = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style>
.card-detail {
  display: flex;
  justify-content: center !important;
  flex-direction: column;
  text-align: center;
}
.card-detail img {
  width: 100%;
}
</style>
